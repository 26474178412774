











import { Component, Prop, Watch } from "vue-property-decorator";
import { Account } from "@/interfaces/Account";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import djlMultiselect from "@/components/form-components/djlMultiselect.vue";
import FilterSelect from "@/components/form-components/search/FilterSelect.vue";

@Component({
  components: { FilterSelect, djlMultiselect },
})
@Translatable("form-components.search")
export default class AccountFilter extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  @Prop()
  private readonly accounts!: Account[];

  @Prop()
  private readonly selectedAccountId: string | undefined;

  @Prop()
  private readonly customLabel!: (account: Account) => string;

  private selectedAccount: Account | null = null;

  mounted() {
    this.setAccount();
  }

  @Watch("selectedAccountId")
  @Watch("accounts")
  private setAccount() {
    this.selectedAccount = this.selectedAccountId
      ? this.accounts.find(
          (account) => account.id === this.selectedAccountId
        ) ?? null
      : null;
  }

  private selectAccount(newAccount: Account | null): void {
    this.$emit("account-changed", newAccount);
  }
}
