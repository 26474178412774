
































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import Multiselect from "vue-multiselect";

@Component({
  components: { Multiselect },
})
@Translatable("form-components.search")
export default class FilterSelect<T> extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  @Prop({ required: true })
  private readonly name!: string;

  @Prop({ required: true })
  private readonly options!: T[];

  @Prop({ required: true })
  private readonly selectedOption!: T | null;

  @Prop({ required: true })
  private readonly customLabel!: (t: T) => string;

  @Prop()
  private readonly placeholder: string | undefined;

  get id(): string {
    return this.name.toLowerCase().replace(" ", "-") + "-filter";
  }

  get sortedOptions(): T[] {
    const sortedOptions: T[] = [...this.options];
    return sortedOptions.sort((t1: T, t2: T) =>
      this.customLabel(t1).localeCompare(this.customLabel(t2))
    );
  }

  private reset(): void {
    this.onValueChange(null);
  }

  private onValueChange(newValue: T | null) {
    this.$emit("value-change", newValue);
  }
}
